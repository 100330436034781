import React from 'react';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { withUrlGenerator } from '@folklore/react-container';

import PageMeta from '../partials/PageMeta';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/home.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Page de test',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const Group2Page = ({ urlGenerator }) => (
    <div className={styles.container}>
        <PageMeta title={messages.metaTitle} />
        <div className={styles.inner}>
            <Link to={urlGenerator.route('cards', { slug: 'B1', index: 0 })}>Test A</Link>
            <Link to={urlGenerator.route('cards', { slug: 'B2', index: 0 })}>Test B</Link>
        </div>
    </div>
);

Group2Page.propTypes = propTypes;

export default withUrlGenerator()(Group2Page);
