import pages from './pages';

export default {
    locale: 'fr',
    messages: {
        'meta.title': 'VHS',
        'meta.title_401': 'Error 401',
        'meta.title_403': 'Error 403',
        'meta.title_404': 'Error 404',
        'meta.title_500': 'Error 500',

        'errors.title_401': 'Error 401',
        'errors.description_401': 'You are not authorized to access this page.',
        'errors.title_403': 'Error 403',
        'errors.description_403': 'Access to this page is forbidden',
        'errors.title_404': 'Error 404',
        'errors.description_404': 'This page doesn’t exists',
        'errors.title_500': 'Error 500',
        'errors.description_500': 'There was an error',
        'errors.goto_home': 'Go to home',
    },
    routes: {
        home: '/',

        group1: '/1',
        group2: '/2',
        test: '/test',

        cards: '/:slug/:index',

        // A1: '/a-1',
        // A2: '/a-2',
        // B1: '/b-1',
        // B2: '/b-2',
        // tap: '/tap',
        // swipe: '/swipe',
        // menu: '/menu',
        // close: '/close',
        // large: '/large',
    },
    pages,
};
