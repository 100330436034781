/* global GOOGLE_MAP_API_KEY */
import React, {
    useState, useCallback, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { setScriptLoaded as setScriptLoadedAction } from '../../actions/LayoutActions';

import OverDeck from './OverDeck';

import mapStyles from '../../lib/map.json';

import styles from '../../styles/partials/map.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    visible: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    setScriptLoaded: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    // Montréal
    center: {
        lat: 45.52,
        lng: -73.7,
    },
    className: null,
};

const Maps = ({
    items, center, visible, loaded, setScriptLoaded, className,
}) => {
    const mapRef = useRef(null);
    const map = useRef(null);
    const markers = useRef([]);

    const [index, setIndex] = useState(null);
    const onClose = useCallback(() => {
        setIndex(null);
    }, [setIndex]);

    const createMap = useCallback(() => {
        map.current = new window.google.maps.Map(mapRef.current, {
            zoom: 11,
            center,
            disableDefaultUI: true,
            styles: mapStyles,
        });
    }, [map, mapRef]);

    const createMarkers = useCallback(() => {
        items.forEach((item, i) => {
            const marker = new window.google.maps.Marker({
                position: { lat: item.lat, lng: item.lng },
                map: map.current,
            });
            marker.addListener('click', () => {
                setIndex(i);
            });
            markers.current.push(marker);
        });
    }, [map, mapRef, markers]);

    useEffect(() => {
        // if (!loaded) {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', () => {
            createMap();
            createMarkers();
            setScriptLoaded('googleMaps');
        });
        // }
    }, [loaded]);

    return (
        // Important! You must set the container height explicitly
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div id="map" ref={mapRef} style={{ width: '100%', height: '100%' }} />
            {visible ? <OverDeck index={index} items={items} onClose={onClose} /> : null}
        </div>
    );
};

Maps.propTypes = propTypes;
Maps.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ layout: { scripts } }) => ({
        loaded: !!scripts.googleMaps,
    }),
    dispatch => ({
        setScriptLoaded: script => dispatch(setScriptLoadedAction(script)),
    }),
)(Maps);

export default WithStateContainer;
