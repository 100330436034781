import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import Close from '../icons/Close';

import styles from '../../styles/buttons/close-button.scss';

const propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    onClick: null,
    className: null,
};

const CloseButton = ({ onClick, className }) => (
    <Button
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        onClick={onClick}
    >
        <Close />
    </Button>
);

CloseButton.propTypes = propTypes;
CloseButton.defaultProps = defaultProps;

export default CloseButton;
