import {
    SET_SIZE,
    SET_SHOW_MENU,
    SET_MENU_OPEN,
    SET_MENU_TYPE,
    SET_MENU_SIZE,
    SET_MENU_CLOSE_TYPE,
    SET_INTERACTION_TYPE,
    SET_CARD_INDEX,
    SET_SCROLL_INDEX,
    SET_MENU_INDEX,
    SET_SCRIPT_LOADED,
} from '../actions/LayoutActions';

const initialState = {
    size: {
        width: window.innerWidth || 0,
        height: window.innerHeight || 0,
    },
    showMenu: true,
    menuOpen: false,
    menuType: 'normal',
    menuSize: 'normal',
    menuCloseType: 'arrow',
    interactionType: 'swipe',
    cardIndex: 0,
    scrollIndex: 0,
    menuIndex: 0,
    color: '#ff1798',
    scripts: {},
};

const LayoutReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
    case SET_SIZE:
        return {
            ...state,
            size: {
                ...action.payload,
            },
        };
    case SET_SHOW_MENU:
        return {
            ...state,
            showMenu: action.payload,
        };
    case SET_MENU_OPEN:
        return {
            ...state,
            menuOpen: action.payload,
        };
    case SET_MENU_TYPE:
        return {
            ...state,
            menuType: action.payload,
        };
    case SET_MENU_SIZE:
        return {
            ...state,
            menuSize: action.payload,
        };
    case SET_MENU_CLOSE_TYPE:
        return {
            ...state,
            menuCloseType: action.payload,
        };
    case SET_INTERACTION_TYPE:
        return {
            ...state,
            interactionType: action.payload,
        };
    case SET_CARD_INDEX:
        return {
            ...state,
            cardIndex: parseInt(action.payload, 10),
        };
    case SET_SCROLL_INDEX:
        return {
            ...state,
            scrollIndex: parseInt(action.payload, 10),
        };
    case SET_MENU_INDEX:
        return {
            ...state,
            menuIndex: parseInt(action.payload, 10),
        };
    case SET_SCRIPT_LOADED:
        return {
            ...state,
            scripts: {
                ...state.scripts,
                [action.payload]: true,
            },
        };
    default:
        return state;
    }
};

export default LayoutReducer;
