import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BarChart, XAxis, Bar } from 'recharts';

import styles from '../../styles/partials/chart.scss';

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
};

const defaultProps = {
    data: [],
    className: null,
};

const CustomizedLabel = ({
    x, y, total, value,
}) => (
    <text
        x={x}
        y={y}
        dy={-8}
        dx={60}
        fontSize="40"
        fontFamily="Garage Gothic"
        fill="white"
        textAnchor="middle"
    >
        {((value / total) * 100).toFixed(0)}
%
    </text>
);
CustomizedLabel.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    value: PropTypes.number,
    total: PropTypes.number.isRequired,
};
CustomizedLabel.defaultProps = {
    x: 0,
    y: 0,
    value: 0,
};

const Chart = ({ data, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <BarChart width={320} height={320} data={data}>
            <XAxis dataKey="name" />
            <Bar
                dataKey="amt"
                fill="#FFFFFF"
                label={(
                    <CustomizedLabel
                        total={data.reduce((acc, i) => {
                            acc += i.amt; // eslint-disable-line
                            return acc;
                        }, 0)}
                    />
                )}
            />
        </BarChart>
    </div>
);

Chart.propTypes = propTypes;
Chart.defaultProps = defaultProps;

export default Chart;
