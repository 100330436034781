/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import CardButton from '../buttons/CardButton';

import styles from '../../styles/menus/scroll-menu.scss';

const propTypes = {
    current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
        }),
    ),
    className: PropTypes.string,
};

const defaultProps = {
    current: 0,
    items: [],
    className: null,
};

const ScrollMenu = ({ items, current, className }) => {
    const color = useSelector(state => state.layout.color);
    const scrollRef = useRef([]);
    const itemRefs = items.map(() => useRef(null));
    useEffect(() => {
        if (itemRefs[current].current) {
            itemRefs[current].current.scrollIntoView();
        }
    }, []);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={scrollRef}
        >
            {items.map((item, i) => (
                <div
                    className={styles.inner}
                    key={`scroll-item-${i}`}
                    style={{
                        backgroundColor: current === i ? color : null,
                    }}
                    ref={itemRefs[i]}
                >
                    <CardButton className={styles.button} index={i}>
                        <div
                            className={styles.background}
                            style={{
                                backgroundImage: `url(${items[i].src})`,
                            }}
                        />
                    </CardButton>
                </div>
            ))}
        </div>
    );
};
ScrollMenu.propTypes = propTypes;
ScrollMenu.defaultProps = defaultProps;

export default ScrollMenu;
