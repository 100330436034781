/**
 * Constants
 */
export const SET_ERRORS = 'site@SET_ERRORS';
export const SET_PAGE = 'site@SET_PAGE';

/**
 * Actions creator
 */
export const setErrors = payload => ({
    type: SET_ERRORS,
    payload,
});

export const resetErrors = () => ({
    type: SET_ERRORS,
    payload: null,
});

export const setPage = payload => ({
    type: SET_PAGE,
    payload,
});
