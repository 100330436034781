import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { useDispatch, useSelector } from 'react-redux';

import {
    setScrollIndex as setScrollIndexAction,
    // setCardIndex as setCardIndexAction,
} from '../../actions/LayoutActions';

import styles from '../../styles/partials/touch-zone.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    interactionType: PropTypes.string,
    fill: PropTypes.bool,
    style: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    interactionType: null,
    style: null,
    fill: false,
    className: null,
};

const TouchZone = ({
    index, interactionType: IT, style, fill, className,
}) => {
    const movedRef = useRef(false);
    const touchXRef = useRef(null);
    const touchYRef = useRef(null);

    const {
        interactionType: defaultIT,
        size: { width },
    } = useSelector(state => state.layout);
    const interactionType = IT || defaultIT;

    const hasTap = interactionType === 'tap' || interactionType === 'both';

    const dispatch = useDispatch();
    const setScrollIndex = useCallback(idx => dispatch(setScrollIndexAction(idx)), [dispatch]);
    // const setCardIndex = useCallback(idx => dispatch(setCardIndexAction(idx)), [dispatch]);

    const onTouchStart = useCallback(
        throttle((e) => {
            // e.persist();
            movedRef.current = true;
            setTimeout(() => {
                movedRef.current = false;
            }, 1300);
            const { touches } = e;
            if (touches && touches.length > 0 && touches[0].clientX) {
                touchXRef.current = touches[0].clientX;
                touchYRef.current = touches[0].clientY;
            }
        }, 10),
        [dispatch, touchXRef, touchYRef],
    );

    const onTouchMove = useCallback(
        throttle((e) => {
            movedRef.current = false;
            const { touches } = e;
            if (touches && touches.length > 0 && touches[0].clientX) {
                touchXRef.current = touches[0].clientX;
                touchYRef.current = touches[0].clientY;
            }
        }, 10),
        [dispatch, touchXRef, touchYRef],
    );

    const onPointerDown = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { clientX } = e;
            if (movedRef.current === false && !clientX) {
                return;
            }

            let nextIndex = index;
            const x = touchXRef.current || clientX;
            if (x > width / 3) {
                nextIndex += 1;
            } else if (nextIndex > 0) {
                nextIndex -= 1;
            }
            setScrollIndex(nextIndex);
            // setCardIndex(nextIndex);
            // } else {
            //     const y = touchYRef.current || clientY;
            //     if (y > height / 3) {
            //         setScrollIndex(index + 1);
            //     } else {
            //         setScrollIndex(index - 1);
            //     }
            // }
        },
        [index, touchXRef, touchYRef, dispatch],
    );

    return (
        // eslint-disable-next-line
        <div
            // type="button"
            className={classNames([
                styles.container,
                {
                    [styles.fill]: fill,
                    [className]: className !== null,
                },
            ])}
            style={style}
            onMouseDown={hasTap ? onPointerDown : null}
            onTouchEnd={hasTap ? onPointerDown : null}
            onTouchStart={hasTap ? onTouchStart : null}
            onTouchMove={hasTap ? onTouchMove : null}
        />
    );
};

TouchZone.propTypes = propTypes;
TouchZone.defaultProps = defaultProps;

const WithIntl = TouchZone;

export default WithIntl;
