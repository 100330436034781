import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import Button from './Button';
import ArrowBig from '../icons/ArrowBig';
import Close from '../icons/Close';

import { setMenuOpen as setMenuOpenAction } from '../../actions/LayoutActions';

import styles from '../../styles/buttons/menu-button.scss';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    closeType: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    closeType: null,
    className: null,
};

const MenuButton = ({
    width, height, closeType, className,
}) => {
    const layout = useSelector(state => state.layout);
    const { menuOpen, menuCloseType } = layout || {};
    const dispatch = useDispatch();
    const setMenuOpen = useCallback(open => dispatch(setMenuOpenAction(open)), [dispatch]);
    const type = closeType || menuCloseType;

    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            onClick={() => setMenuOpen(!menuOpen)}
            style={{ width, height, borderRadius: width !== null ? width / 2 : null }}
        >
            {type === 'arrow' ? <ArrowBig /> : <Close />}
        </Button>
    );
};

MenuButton.propTypes = propTypes;
MenuButton.defaultProps = defaultProps;

export default MenuButton;
