/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash/isString';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { pascalCase } from '../../lib/utils';
import CardObserver from '../partials/CardObserver';
import * as CardComponents from './index';

import {
    setCardIndex as setCardIndexAction,
    setScrollIndex as setScrollIndexAction,
    setMenuIndex as setMenuIndexAction,
} from '../../actions/LayoutActions';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/cards/cards.scss';

const propTypes = {
    componentProps: PropTypes.object, // eslint-disable-line
    items: PropTypes.arrayOf(PropTypes.object),
    match: AppPropTypes.match.isRequired, // eslint-disable-line
    history: AppPropTypes.history.isRequired, // eslint-disable-line
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    itemStyle: PropTypes.object, // eslint-disable-line
    debug: PropTypes.bool,
};

const defaultProps = {
    componentProps: {},
    items: [],
    className: null,
    itemClassName: null,
    itemStyle: {},
    debug: false,
};

const Cards = ({
    componentProps,
    items,
    match,
    history,
    className,
    itemClassName,
    itemStyle,
    debug,
}) => {
    if (items.length < 1) return null;
    const timeRef = useRef(0);
    const scrollRef = useRef();
    const { current = null } = scrollRef;

    const {
        menuOpen,
        interactionType,
        scrollIndex,
        cardIndex,
        menuIndex,
        size: { width, height },
    } = useSelector(state => state.layout);

    const dispatch = useDispatch();
    const setCardIndex = useCallback(idx => dispatch(setCardIndexAction(idx)), [dispatch]);
    const setScrollIndex = useCallback(idx => dispatch(setScrollIndexAction(idx)), [dispatch]);
    const setMenuIndex = useCallback(idx => dispatch(setMenuIndexAction(idx)), [dispatch]);
    const urlIndex = useRef(match.params.index || 0);
    // const urlIndex = match.params.index || 0;

    // Scrolls to the right place
    const scrollTo = useCallback(
        (idx) => {
            if (current !== null) {
                if (width < height) {
                    // Horizontal
                    current.scrollTo(idx * width, 0);
                } else {
                    // Vertical
                    current.scrollTo(0, idx * height);
                }
            }
        },
        [current, width, height],
    );

    // Set the initial index and back effects
    useEffect(() => {
        // console.log('bro initial setup', urlIndex.current);
        if (urlIndex.current) {
            setMenuIndex(urlIndex.current);
            setScrollIndex(urlIndex.current);
            setCardIndex(urlIndex.current);
            scrollTo(urlIndex.current);
        }
    }, [urlIndex.current, current]);

    // On menuIndex change, always scroll to the right place
    useEffect(() => {
        // console.log('menuIndex changed, sync others');
        if (current !== null) {
            // scrollTo(menuIndex);
            setScrollIndex(menuIndex);
            setCardIndex(menuIndex);
        }
    }, [menuIndex, current, scrollTo]);

    // On scrollIndex change, sync and scroll
    useEffect(() => {
        // console.log('scrollIndex change, sync and scroll');
        if (current !== null && scrollIndex !== cardIndex) {
            scrollTo(scrollIndex);
            setMenuIndex(scrollIndex);
            setCardIndex(scrollIndex);
        }
    }, [scrollIndex, current, setCardIndex, setMenuIndex]);

    // Manually change the url to always match cardIndex
    // - should detect a back..... uhuh no not now.
    useEffect(() => {
        const path = `/${match.params.slug}/${cardIndex}`;
        if (history.location.path !== path) {
            history.push(path, document.title);
        }
    }, [cardIndex]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    // [styles.tap]: interactionType === 'tap',
                    [styles.disabled]: menuOpen,
                    [className]: className !== null,
                },
            ])}
            ref={scrollRef}
        >
            {items.map((item, i) => {
                const CardComponent = isString(item.type)
                    ? CardComponents[pascalCase(item.type)]
                    : CardComponents.Image;
                return (
                    <div
                        key={`section-item-${i}`}
                        className={classNames([
                            styles.item,
                            { [itemClassName]: itemClassName !== null },
                        ])}
                        style={{
                            ...itemStyle,
                            width: `${width > height ? height * (320 / 500) : width}px`,
                            height: `${height}px`,
                        }}
                    >
                        <CardObserver
                            index={i}
                            item={item}
                            timeRef={timeRef}
                            active={
                                interactionType === 'swipe'
                                || (interactionType === 'both'
                                    && scrollIndex > i - 3
                                    && scrollIndex < i + 3)
                            }
                        >
                            <CardComponent {...componentProps} {...item} index={i} />
                            {debug === true ? <p className={styles.cardCount}>{i}</p> : null}
                        </CardObserver>
                    </div>
                );
            })}
        </div>
    );
};

Cards.propTypes = propTypes;
Cards.defaultProps = defaultProps;

const WithRouter = withRouter(Cards);

export default WithRouter;
