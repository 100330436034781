import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useElementSize } from '../hooks/useElementSize';
import { fancyTimeFormat } from '../../lib/utils';

import styles from '../../styles/partials/progress.scss';

const propTypes = {
    current: PropTypes.number,
    duration: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    current: 3,
    duration: 10,
    onClick: null,
    className: null,
};

const Progress = ({
    current, duration, onClick, className,
}) => {
    const [ref, size] = useElementSize();
    const { width = 0 } = size;
    const style = { width: width - 20 || '100%' };
    const onSeek = useCallback(
        (e) => {
            const proportionalWidth = ((width - 20) / width) * width;
            const proportionalClientX = ((e.clientX - 20) / e.clientX) * e.clientX;
            onClick((proportionalClientX / proportionalWidth) * duration);
        },
        [width, duration, size],
    );
    const barRatio = current / duration;
    const barWidth = Math.round(barRatio * width || 0);
    // console.log(size);

    return (
        <button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            type="button"
            onClick={onSeek}
            ref={ref}
        >
            <div className={styles.inner} style={style}>
                <div className={styles.bar} style={{ width: barWidth }}>
                    {barRatio < 0.75 ? (
                        <span className={styles.label}>{fancyTimeFormat(current)}</span>
                    ) : null}
                </div>
            </div>
            <span className={styles.label}>{fancyTimeFormat(duration)}</span>
        </button>
    );
};

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export default Progress;
