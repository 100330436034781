/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Survey from '../partials/Survey';
import Image from '../partials/Image';
import TouchZone from '../partials/TouchZone';

import styles from '../../styles/cards/video.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    src: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    src: null,
    choices: [],
    className: null,
};

const SurveyCard = ({
    index, src, choices, className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <Image className={styles.image} url={src} isBackground />
        <Survey choices={choices} />
        <TouchZone interactionType="tap" index={index} />
    </div>
);

SurveyCard.propTypes = propTypes;
SurveyCard.defaultProps = defaultProps;

export default React.memo(SurveyCard);
