import config from './config';
import cards from './cards';

export default {
    home: {
        config,
        cards,
    },
    group1: {
        config,
        cards,
    },
    group2: {
        config,
        cards,
    },
    test: {
        config,
        cards,
    },
    A1: {
        config: {
            ...config,
            interactionType: 'tap',
        },
        cards,
    },
    A2: {
        config: {
            ...config,
            interactionType: 'swipe',
        },
        cards,
    },
    B1: {
        config: {
            ...config,
            interactionType: 'swipe',
            menuType: 'scroll',
            menuCloseType: 'close',
        },
        cards,
    },
    B2: {
        config: {
            ...config,
            interactionType: 'tap',
            menuType: 'scroll',
            menuCloseType: 'close',
        },
        cards,
    },
    both: {
        config: {
            ...config,
            interactionType: 'both',
        },
        cards,
    },
    tap: {
        config: {
            ...config,
            interactionType: 'tap',
        },
        cards,
    },
    swipe: {
        config: {
            ...config,
            interactionType: 'swipe',
        },
        cards,
    },
    menu: {
        config: {
            ...config,
            menuType: 'scroll',
        },
        cards,
    },
    close: {
        config: {
            ...config,
            menuType: 'scroll',
            menuCloseType: 'close',
        },
        cards,
    },
    large: {
        config: {
            ...config,
            menuType: 'scroll',
            menuSize: 'large',
            menuCloseType: 'close',
        },
        cards,
    },
};
