import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFonts } from '@folklore/fonts';

import Menu from '../menus/Menu';

import useWindowSize from '../hooks/useWindowSize';

import { setSize as setSizeAction } from '../../actions/LayoutActions';

import styles from '../../styles/layouts/main.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    isPrerender: PropTypes.bool.isRequired,
    showMenu: PropTypes.bool.isRequired,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
    setSize: PropTypes.func.isRequired,
};

const defaultProps = {
    fonts: {
        google: {
            families: ['Open Sans:400,600,700'],
        },
        custom: {
            families: ['Garage Gothic', 'Brandon Grotesque', 'Sina Nova:400,700,400i,700i'],
        },
    },
};

const MainLayout = ({
    fonts, children, isPrerender, showMenu, setSize,
}) => {
    const { loaded: fontsLoaded } = useFonts(fonts);

    useWindowSize({
        onChange: size => setSize(size),
    });

    const innerStyle = {
        opacity: fontsLoaded || isPrerender ? 1 : 0,
    };

    return (
        <div className={styles.container}>
            {showMenu ? <Menu /> : null}
            <div className={styles.inner} style={innerStyle}>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site: { isPrerender }, layout: { showMenu } }) => ({
        isPrerender: isPrerender || false,
        showMenu,
    }),
    dispatch => ({
        setSize: size => dispatch(setSizeAction(size)),
    }),
)(MainLayout);

export default WithStateContainer;
