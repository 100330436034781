import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Close = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        className={className}
        xmlSpace="preserve"
    >
        <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="1"
            y1="1"
            x2="13"
            y2="13"
        />
        <line
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="1"
            y1="13"
            x2="13"
            y2="1"
        />
    </svg>
);

Close.propTypes = propTypes;
Close.defaultProps = defaultProps;

export default Close;
