/**
 * Constants
 */
export const SET_SIZE = 'layout@SET_SIZE';
export const SET_SHOW_MENU = 'layout@SET_SHOW_MENU';
export const SET_MENU_OPEN = 'layout@SET_MENU_OPEN';
export const SET_MENU_TYPE = 'layout@SET_MENU_TYPE';
export const SET_MENU_SIZE = 'layout@SET_MENU_SIZE';
export const SET_MENU_CLOSE_TYPE = 'layout@SET_MENU_CLOSE_TYPE';
export const SET_INTERACTION_TYPE = 'layout@SET_INTERACTION_TYPE';

// This is different for a very good reason, do not mix with card index
export const SET_CARD_INDEX = 'layout@SET_CARD_INDEX';
export const SET_SCROLL_INDEX = 'layout@SET_SCROLL_INDEX'; // For the card observer
export const SET_MENU_INDEX = 'layout@SET_MENU_INDEX'; // For the card observer

export const SET_SCRIPT_LOADED = 'layout@SET_SCRIPT_LOADED'; // For the card observer

/**
 * Actions creator
 */
export const setSize = payload => ({
    type: SET_SIZE,
    payload,
});

export const setShowMenu = payload => ({
    type: SET_SHOW_MENU,
    payload,
});

export const setMenuOpen = payload => ({
    type: SET_MENU_OPEN,
    payload,
});

export const setMenuType = payload => ({
    type: SET_MENU_TYPE,
    payload,
});

export const setMenuSize = payload => ({
    type: SET_MENU_SIZE,
    payload,
});

export const setMenuCloseType = payload => ({
    type: SET_MENU_CLOSE_TYPE,
    payload,
});

export const setInteractionType = payload => ({
    type: SET_INTERACTION_TYPE,
    payload,
});

export const setCardIndex = payload => ({
    type: SET_CARD_INDEX,
    payload,
});

export const setScrollIndex = payload => ({
    type: SET_SCROLL_INDEX,
    payload,
});

export const setMenuIndex = payload => ({
    type: SET_MENU_INDEX,
    payload,
});

export const setScriptLoaded = payload => ({
    type: SET_SCRIPT_LOADED,
    payload,
});
