import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../buttons/Button';
import Chart from './Chart';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/survey.scss';

const propTypes = {
    choices: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    choices: [],
    className: null,
};

const Survey = ({ choices, className }) => {
    const [answered, setAnswered] = useState(false);
    const [answers, setAnswer] = useState(choices);

    const onClick = useCallback(
        (index) => {
            const final = answers.concat([]);
            final.splice(index, 1, { ...answers[index], amt: answers[index].amt + 1 });
            setAnswer(final);
            setAnswered(true);
        },
        [answers, setAnswer, setAnswered],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {answered ? (
                <Chart className={styles.chart} data={answers} />
            ) : (
                <div className={styles.buttons}>
                    {choices.map((choice, idx) => (
                        <Button
                            {...choice}
                            key={`survey-${choice.name}`}
                            className={styles.button}
                            onClick={(e) => {
                                e.preventDefault();
                                onClick(idx);
                            }}
                        >
                            <span>{choice.name}</span>
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};

Survey.propTypes = propTypes;
Survey.defaultProps = defaultProps;

export default Survey;
