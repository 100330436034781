import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Pause = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        className={className}
        xmlSpace="preserve"
    >
        <rect fill={color} width="8" height="20" />
        <rect x="12" fill={color} width="8" height="20" />
    </svg>
);

Pause.propTypes = propTypes;
Pause.defaultProps = defaultProps;

export default Pause;
