import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OverCard from './OverCard';

import styles from '../../styles/partials/over-deck.scss';

const propTypes = {
    index: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
    onClose: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    index: 0,
    items: [],
    onClose: null,
    className: null,
};

const OverDeck = ({
    index, items, onClose, className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <div className={styles.inner}>
            {items.map((it, i) => (
                <div key={`over-card-${i + 1}-${it.text}`}>
                    <OverCard
                        className={classNames([
                            styles.card,
                            {
                                [styles.open]: i === index,
                                [className]: className !== null,
                            },
                        ])}
                        onClose={onClose !== null ? onClose : null}
                        {...it}
                    />
                </div>
            ))}
        </div>
    </div>
);

OverDeck.propTypes = propTypes;
OverDeck.defaultProps = defaultProps;

export default OverDeck;
