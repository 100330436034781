/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import isString from 'lodash/isString';

import * as CardComponents from './flatCards'; // eslint-disable-line

import TouchZone from '../partials/TouchZone';

import { pascalCase } from '../../lib/utils';

import styles from '../../styles/cards/swipe.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    items: PropTypes.array, // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    items: null,
    className: null,
};

const SwipeCard = ({ items, index, className }) => {
    const {
        interactionType,
        size: { height },
    } = useSelector(state => state.layout);
    const hasTap = interactionType === 'tap' || interactionType === 'both';

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {items.map((item, i) => {
                    const CardComponent = isString(item.type)
                        ? CardComponents[pascalCase(item.type)]
                        : CardComponents.Image;
                    return (
                        <div
                            key={`swipe-${index}-${i}`}
                            style={{ position: 'relative', width: '100%', height }}
                        >
                            <CardComponent
                                {...item}
                                className={classNames([styles.card])}
                                index={i}
                                interactionType="none"
                            />
                        </div>
                    );
                })}
                {hasTap ? <TouchZone index={index} /> : null}
            </div>
        </div>
    );
};

SwipeCard.propTypes = propTypes;
SwipeCard.defaultProps = defaultProps;

export default React.memo(SwipeCard);
