import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Muted = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="38px"
        height="27px"
        viewBox="0 0 38 27"
        className={className}
        xmlSpace="preserve"
    >
        <g>
            <image
                style={{ overflow: 'visible', opacity: 0.75 }}
                width="38"
                height="27"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAcCAYAAADiHqZbAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
    GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAwdJREFUeNrclwlP20AQhVnH5CQc
    JfRSD9RSqVL//y+iRxpSEQwxSXx1VvoGDYsNlCIa1dJT7M169s3sm9nxxsYaX+4R36/WhZwDkRkr
    IVj9S3KeUEsQA/9cCDJBbkj+9dV6ADFPqCfYFuwKtgSbECog9yhX/ABifcGe4IBfH/2ZYAw5xUN2
    pbqNnGt4dobYvuCN4L1gBJFvgoVgLlia6EVGl1aPzqBifhkSjBtEbo06tt9v5TOIfRJ85HmO3n4Y
    DWqU24EuSyOnGNt+fIVT1zQbGwIR2vEGO/zXMuNDwStIfRYcEsmJ4Dvv2fnemR00GbPwivW8/S73
    ntQZSHH0ipxGzBscYHCXe/W8w/hrwZHgg+A5Rua8GxknYzP/JU7kbL3juUeUTnFOM75QCcRGG21I
    vQUjCG5Cbgghv+ALFkgDYtbRLYgdYcsROUfU2jj2FTu/wuphyXUMuS+Cd3jfZrEuBHcg7fA0akgk
    TaAREhiYeRFEx8giTIob5HRbRxBUwXeMgNtmq/MaUpVZKDdzBkSxZ3R2gnPnggu2vAiz1QUE+xTY
    PUMuqsniplpWmQxcmCTQwu3fSXBgzv0FhAtbcqInaCxcUC/r/nNNJ0RlDu4McSZkkbtlW5sO+chk
    eB+tRkQmYc6KXdId2iayqZHCVbYqMR/mqeCYCdM7EqJqiFSL+T3eX6Gx0mRzgb0DTp0T9JdpYlhy
    S87IY8Z+3qOUZA1diJJUXY2N845IDYmSRlh3yIXZWuLdjIWSO4rwwhThrKaXK5hzSh2bEJWExbVx
    6DDnRqaGmsvMOXnecHxNzeF+icfzgKA6ekblT7GvJUMjt8/7CyJ7Yc7Wa5GzRvWYCQ/+BEI659Ic
    /OeMa7uUQ66g8pfYXJqyMkGXBcTOsFHWdSV2S4qalkk7Xf3/0rRME+N5YTqQDMfKwG6KQy3W1aYg
    s/KIb2v2gmc95LXIpkGzOTO9XGkIbdT0czlEnNH8jW+QP+1WtYZ1SZg+YwsilwbeuwZn3X064bX+
    wPnvPg2f7KN6ra/fAgwAr18kbSaFs30AAAAASUVORK5CYII="
            />
            <g>
                <g>
                    <polygon
                        fill="#F6F6F6"
                        points="10.5,9.2 4.9,9.2 4.9,14.9 10.5,14.9 16.1,20.5 16.1,3.6 "
                    />
                    <line
                        fill="none"
                        stroke={color}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="31.5"
                        y1="8.3"
                        x2="24"
                        y2="15.8"
                    />
                    <line
                        fill="none"
                        stroke={color}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        x1="24"
                        y1="8.3"
                        x2="31.5"
                        y2="15.8"
                    />
                </g>
            </g>
        </g>
    </svg>
);

Muted.propTypes = propTypes;
Muted.defaultProps = defaultProps;

export default Muted;
