import isObject from 'lodash/isObject';

export const pascalCase = str => str
    .replace(/[^a-z0-9]+/gi, ' ')
    .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
    .replace(/\s+/gi, '');

export const isMessage = message => isObject(message) && typeof message.id !== 'undefined';

export const getComponentFromName = (name, components, defaultComponent = null) => {
    const componentName = pascalCase(name);
    return components[componentName] || defaultComponent;
};

export const buildThresholdArray = () => Array.from(Array(10).keys(), i => i / 10);

export const fancyTimeFormat = (time) => {
    // Hours, minutes and seconds
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor(time % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
        ret += `${hrs}:${mins < 10 ? '0' : ''}`;
    }

    ret += `${mins}:${secs < 10 ? '0' : ''}`;
    ret += `${secs}`;
    return ret;
};
