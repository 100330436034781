/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from '../partials/Image';
import TouchZone from '../partials/TouchZone';

import styles from '../../styles/cards/image.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    interactionType: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    src: null,
    interactionType: null,
    className: null,
};

const ImageCard = ({
    index, interactionType, src, className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <Image className={styles.image} url={src} isBackground />
        <TouchZone index={index} interactionType={interactionType} />
    </div>
);

ImageCard.propTypes = propTypes;
ImageCard.defaultProps = defaultProps;

export default React.memo(ImageCard);
