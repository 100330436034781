import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CloseButton from '../buttons/CloseButton';

import styles from '../../styles/partials/over-card.scss';

const propTypes = {
    text: PropTypes.string,
    src: PropTypes.string,
    onClose: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    text: '',
    src: null,
    onClose: null,
    className: null,
};

const OverCard = ({
    text, src, onClose, className,
}) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
    >
        <CloseButton className={styles.close} onClick={onClose !== null ? onClose : null} />
        <div className={styles.inner}>
            <p className={styles.text}>{text}</p>
            <img src={src} alt="card-img" />
        </div>
    </div>
);

OverCard.propTypes = propTypes;
OverCard.defaultProps = defaultProps;

export default OverCard;
