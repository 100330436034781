/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import MenuButton from '../buttons/MenuButton';
import SwipeMenu from './SwipeMenu';
import ScrollMenu from './ScrollMenu';

import background from '../../img/vhs/menu/background.jpg';

import styles from '../../styles/menus/menu.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Menu = ({ className }) => {
    const page = useSelector(state => state.site.page);
    const { cards } = page || {};
    const layout = useSelector(state => state.layout);
    const {
        menuType, menuSize, menuCloseType, menuOpen, size, cardIndex,
    } = layout || {};

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.open]: menuOpen,
                    [styles[menuSize]]: true,
                    [styles[menuType]]: true,
                    [className]: className !== null,
                },
            ])}
            style={{ backgroundImage: `url(${background})` }}
        >
            <h1 className={styles.title}>VHS</h1>
            {menuOpen && menuType === 'swipe' ? (
                <SwipeMenu current={cardIndex} items={cards} {...size} />
            ) : null}
            {menuOpen && menuType === 'scroll' ? (
                <ScrollMenu current={cardIndex} items={cards} {...size} />
            ) : null}
            <MenuButton className={menuCloseType === 'arrow' ? styles.arrow : styles.button} />
        </div>
    );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
