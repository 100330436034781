import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Button from './Button';

import {
    setScrollIndex as setScrollIndexAction,
    setMenuOpen as setMenuOpenAction,
} from '../../actions/LayoutActions';

import styles from '../../styles/buttons/card-button.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const CardButton = ({ index, children, className }) => {
    const dispatch = useDispatch();
    const setScrollIndex = useCallback(open => dispatch(setScrollIndexAction(open)), [dispatch]);
    const setMenuOpen = useCallback(open => dispatch(setMenuOpenAction(open)), [dispatch]);

    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            onClick={(e) => {
                e.stopPropagation();
                setScrollIndex(index);
                setMenuOpen(false);
            }}
        >
            {children}
        </Button>
    );
};

CardButton.propTypes = propTypes;
CardButton.defaultProps = defaultProps;

export default CardButton;
