/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import clamp from 'lodash/clamp';
import { useSprings, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import CardButton from '../buttons/CardButton';

import styles from '../../styles/menus/swipe-menu.scss';

const propTypes = {
    current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
        }),
    ),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    current: 0,
    items: [],
    className: null,
};

const SwipeMenu = ({
    width: containerWidth,
    height: containerHeight,
    items,
    current,
    className,
}) => {
    const width = Math.floor(containerHeight * 0.33);
    const height = Math.floor(containerHeight * 0.56);

    // eslint-disable-next-line max-len
    const offset = containerWidth > containerHeight
        ? Math.floor(containerHeight / 2) - width / 2
        : Math.floor(containerWidth / 2) - width / 2;

    const index = useRef(current);
    const [props, set] = useSprings(items.length, i => ({
        x: i * width + offset,
        sc: 1,
        display: 'block',
        // height: '30vh',
    }));

    const callback = useCallback(
        ({
            down, delta: [xDelta], direction: [xDir], distance, cancel,
        }) => {
            // console.log(down, velocity, distance > width / 4);
            if (down && distance > width / 4) {
                const next = 1; // Math.ceil(velocity);
                cancel(
                    (index.current = clamp(
                        index.current + (xDir > 0 ? -next : next),
                        0,
                        items.length - 1,
                    )),
                );
            }

            set((i) => {
                if (i < index.current - 3 || i > index.current + 3) return { display: 'none' };
                const x = (i - index.current) * width + (down ? xDelta : 0) + offset;
                const sc = down ? 1 - distance / width / 2 : 1;
                return {
                    x,
                    sc: i !== index.current ? sc * 0.8 : sc,
                    display: 'block',
                };
            });
        },
    );

    const bind = useGesture(callback);
    const actions = { ...bind() };

    useEffect(() => {
        index.current = current;
        try {
            const e = document.createEvent('MouseEvent');
            actions.onMouseDown(e);
        } catch (e) {
            console.log(e); // eslint-disable-line
        }
        try {
            const e = document.createEvent('TouchEvent');
            actions.onTouchStart(e);
        } catch (e) {
            console.log(e); // eslint-disable-line
        }
    }, [current, index]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={{
                width: `${containerHeight}px`,
                height,
            }}
        >
            {/* eslint-disable-next-line */}
            {props.map(({ x, display, sc }, i) => (
                <animated.div
                    {...actions}
                    key={i}
                    className={styles.inner}
                    style={{
                        display,
                        transform: x.interpolate(xx => `translate3d(${xx}px,0,0)`),
                        width,
                        height,
                    }}
                >
                    <CardButton className={styles.button} index={i}>
                        <animated.div
                            className={styles.background}
                            style={{
                                transform: sc.interpolate(s => `scale(${s})`),
                                backgroundImage: `url(${items[i].src})`,
                            }}
                        />
                    </CardButton>
                </animated.div>
            ))}
        </div>
    );
};

SwipeMenu.propTypes = propTypes;
SwipeMenu.defaultProps = defaultProps;

export default SwipeMenu;
