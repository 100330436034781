import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPage as setPageAction } from '../../actions/SiteActions';

import {
    setMenuType as setMenuTypeAction,
    setMenuSize as setMenuSizeAction,
    setMenuCloseType as setMenuCloseTypeAction,
    setInteractionType as setInteractionTypeAction,
} from '../../actions/LayoutActions';

export default ({ name }) => {
    const pages = useSelector(state => state.site.pages);
    // console.log(pages, name, pages[name]);

    const dispatch = useDispatch();
    const setPage = useCallback((newUrl, newPage) => dispatch(setPageAction(newUrl, newPage)), [
        dispatch,
    ]);

    const setMenuType = useCallback(type => dispatch(setMenuTypeAction(type)), [dispatch]);
    const setMenuSize = useCallback(size => dispatch(setMenuSizeAction(size)), [dispatch]);
    const setMenuCloseType = useCallback(type => dispatch(setMenuCloseTypeAction(type)), [
        dispatch,
    ]);
    const setInteractionType = useCallback(type => dispatch(setInteractionTypeAction(type)));

    useEffect(() => {
        // console.log('usePage');
        const page = pages[name];
        setPage(page);
        setMenuType(page.config.menuType);
        setMenuSize(page.config.menuSize);
        setMenuCloseType(page.config.menuCloseType);
        setInteractionType(page.config.interactionType);
    }, [name]);
};
