/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Image from '../partials/Image';

import NextButton from '../buttons/CardButton';
import ArrowBig from '../icons/ArrowBig';

import TouchZone from '../partials/TouchZone';

import styles from '../../styles/cards/scroll.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    scrollSrc: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    scrollSrc: null,
    className: null,
};

const ScrollCard = ({ scrollSrc, index, className }) => {
    const {
        interactionType,
        size: { width },
    } = useSelector(state => state.layout);
    const hasTap = interactionType === 'tap' || interactionType === 'both';

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {hasTap ? <TouchZone index={index} /> : null}
                <Image className={styles.image} url={scrollSrc} isBackground={false} />
                {width > 767 ? (
                    <NextButton index={index + 1} className={styles.down}>
                        <ArrowBig />
                    </NextButton>
                ) : null}
            </div>
        </div>
    );
};

// {interactionType === 'tap' ? (
//     <NextButton index={index + 1} className={styles.next}>
//         <ArrowBig />
//     </NextButton>
// ) : null}
//
// {interactionType === 'tap' ? (
//     <NextButton index={index - 1} className={styles.previous}>
//         <ArrowBig />
//     </NextButton>
// ) : null}

ScrollCard.propTypes = propTypes;
ScrollCard.defaultProps = defaultProps;

export default React.memo(ScrollCard);
