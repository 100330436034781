import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Pill = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="6px"
        height="10px"
        viewBox="0 0 6 10"
        className={className}
        xmlSpace="preserve"
    >
        <line
            fill="none"
            stroke={color}
            strokeWidth="2.1483"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="1"
            y1="1"
            x2="4.5"
            y2="1"
        />
    </svg>
);

Pill.propTypes = propTypes;
Pill.defaultProps = defaultProps;

export default Pill;
