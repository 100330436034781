import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Unmuted = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="35px"
        height="27px"
        viewBox="0 0 35 27"
        className={className}
        xmlSpace="preserve"
    >
        <g>
            <image
                style={{ overflow: 'visible', opacity: 0.75 }}
                width="35"
                height="27"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAYAAAAJKR1YAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
        GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAuxJREFUeNrMl31P2lAUh+kLICCC
        OiTK2IzTZMm+/7fZP5tmcSrTIS1vbe/OTZ67nHVtWdRtkPxSWu7Lc3/3nHNLrbZlH+8F+5v/CeQh
        Xz3L/hDKvDSQhQhEIbL3qWgtSphQA3s8M0BXggdPgLEQLdGeqC/aFdXVhDXa7IjaqMlcG7c4eAKM
        neBAdCwa8b2BSwmTtoAdiA5FPQA9BW6KoIKK+NC2+7jQEb0SvRGdi06Z0LaLRQu+7wL8Fg15FgKT
        sIDfoMKKYPVVDASs2rrxWnQhesd9RPxcA53hRp+2I7bsO8/qasviPFSYg3FONBikDoy977JqC/Ie
        d+z23Yi+0MZXGZfRdw8XB7T3CpLAufUTyDnjtqWnArapVnzCVp2Jjhgkop+DsZPMRQ+iCc7sM2YA
        yJx+C9pneSAXtA0mHqMBUC6rjoAasto4B2OY0E5wS78W447pcwzsHde5SgaTB2oqoA8Eb18N3GWl
        HQZYqzirKfvt83tVr9z1hAUO0DUOLl3JyAPpTBoTL4dsmYutBv2SksKqXfoGTJNxXTjohWmHf4kh
        DdVmi/bRDgP7qnNaUemdU3Y7psSShZsBU88ljadd2apPmCvjGXbHrOwe6KItMxVnkq5dLu0PiB+f
        OZZc07I65GAiMuCSgScVQW1KYEJVSEfUrDF9bZ9HgjlS8VYItCQVL3l2syHt1wWnt8uoHil+hoa4
        PGHcW8BWVUArgAxbVlYYF6ow6hV6KqucO66eNQCwVf0KoBn9Tb4wuhWulY2PBUfHHb8vyaC2ap8q
        h1yad3E3pe9X0UfRJ+7jqhgyyqUEF/ThOgVipY4Hd7hq600u7Sc4McOdz1yn9CkF0k6lasWeqsqJ
        +m1OAU2JiUi1WZChV4DUgJ4gt7h00+tH0ZucUddIrT6maHrE3QMgK1U0V8BmAMTItcme+07tq9fT
        DjHkAzLLHZShikH3UqYdNv/yJd+UvOSbDQX1r/wNMs/537Z1fxS37vNDgAEA0OQUf54VJG8AAAAA
        SUVORK5CYII="
            />
            <g>
                <g>
                    <polygon
                        fill="#F6F6F6"
                        points="11.1,9.1 5.4,9.1 5.4,14.8 11.1,14.8 16.7,20.4 16.7,3.5 "
                    />

                    <path
                        fill="none"
                        stroke={color}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M23.8,17.9c2.7-1.2,4.5-3.4,4.5-6s-1.8-4.8-4.5-6"
                    />
                </g>
            </g>
        </g>
    </svg>
);

Unmuted.propTypes = propTypes;
Unmuted.defaultProps = defaultProps;

export default Unmuted;
