import { useState, useCallback, useLayoutEffect } from 'react';

export const getSize = (node) => {
    const rect = node.getBoundingClientRect();
    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    };
};

export const useElementSize = (live = true) => {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);

    const ref = useCallback((n) => {
        setNode(n);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            // eslint-disable-next-line
            const measure = () => window.requestAnimationFrame(() => setDimensions(getSize(node)));
            measure();
            if (live) {
                window.addEventListener('resize', measure);
                window.addEventListener('scroll', measure);
                return () => {
                    window.removeEventListener('resize', measure);
                    window.removeEventListener('scroll', measure);
                };
            }
        }
        return () => {};
    }, [node]);

    return [ref, dimensions, node];
};

export default useElementSize;
