import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import Play from '../icons/Play';
import Pause from '../icons/Pause';

import styles from '../../styles/buttons/play-button.scss';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isPlaying: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    isPlaying: false,
    onClick: null,
    className: null,
};

const PlayButton = ({
    width, height, isPlaying, onClick, className,
}) => (
    <Button
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        onClick={onClick}
        style={{ width, height, borderRadius: width !== null ? width / 2 : null }}
    >
        {isPlaying ? <Pause /> : <Play />}
    </Button>
);

PlayButton.propTypes = propTypes;
PlayButton.defaultProps = defaultProps;

export default PlayButton;
