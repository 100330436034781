import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Image from '../partials/Image';
import Button from '../buttons/Button';
import CloseButton from '../buttons/CloseButton';
import TouchZone from '../partials/TouchZone';

import MapInteraction from '../partials/Map';

import styles from '../../styles/cards/map.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    className: null,
};

const MapCard = ({
    index, src, items, className,
}) => {
    const [open, setOpen] = useState(false);
    const { cardIndex } = useSelector(state => state.layout);

    const onToggle = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (open) {
                setOpen(false);
            } else {
                setOpen(true);
            }
        },
        [open, setOpen],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {open ? (
                <>
                    <CloseButton className={styles.close} onClick={onToggle} />
                    <MapInteraction items={items} visible={cardIndex === index} />
                </>
            ) : (
                <>
                    <Button className={styles.button} onClick={onToggle}>
                        Voir la carte
                    </Button>
                    <Image className={styles.image} url={src} isBackground />
                    <TouchZone index={index} />
                </>
            )}
        </div>
    );
};

MapCard.propTypes = propTypes;
MapCard.defaultProps = defaultProps;

export default React.memo(MapCard);
