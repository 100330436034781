/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

// import MenuButton from '../buttons/MenuButton';
import Pill from '../icons/Pill';

import { setMenuOpen as setMenuOpenAction } from '../../actions/LayoutActions';

import styles from '../../styles/menus/header.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Header = ({ className }) => {
    const layout = useSelector(state => state.layout);
    const page = useSelector(state => state.site.page);
    const { cards = [] } = page || {};
    const { menuOpen, cardIndex, color } = layout || {};
    const dispatch = useDispatch();
    const setMenuOpen = useCallback(open => dispatch(setMenuOpenAction(open)), [dispatch]);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.open]: menuOpen,
                    [className]: className !== null,
                },
            ])}
        >
            <button type="button" onClick={setMenuOpen} className={styles.pills}>
                {cards.map((c, i) => (
                    <Pill
                        key={`pill-${i}`}
                        className={styles.pill}
                        color={i <= cardIndex ? color : '#ffffff'}
                    />
                ))}
            </button>
        </div>
    );
};

// <MenuButton closeType="arrow" className={styles.button} />;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
