import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import Image from '../partials/Image';

import TouchZone from '../partials/TouchZone';

import styles from '../../styles/cards/drag.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
    srcDrag: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    width: 0,
    height: 0,
    src: null,
    srcDrag: null,
    className: null,
};

const DragCard = ({
    width, height, src, srcDrag, index, className,
}) => {
    const {
        interactionType,
        color,
        size: { width: frameWidth, height: frameHeight },
    } = useSelector(state => state.layout);

    const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));
    const bind = useDrag(({ local, event, last }) => {
        if (!last && event.preventDefault()) {
            event.preventDefault();
        }
        event.stopPropagation();
        set({ xy: local });
    });
    const offsetX = 0;
    const offsetY = frameHeight / 2 - height / 2;
    const dragOffsetX = (width * 3) / 2;
    const dragOffsetY = (height * 3) / 2;
    const hasTap = interactionType === 'tap' || interactionType === 'both';

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {hasTap ? <TouchZone index={index} /> : null}
                <div
                    style={{
                        transform: `translate3D(${offsetX}px, ${offsetY}px, 0)`,
                    }}
                >
                    <animated.div
                        {...bind()}
                        className={styles.image}
                        style={{
                            width: frameWidth,
                            height,
                            borderColor: color,
                            backgroundColor: color,
                            backgroundImage: `url(${srcDrag})`,
                            backgroundPosition: xy.interpolate(
                                (x, y) => `${x - dragOffsetX}px ${y - dragOffsetY}px`,
                            ),
                        }}
                    />
                </div>
            </div>
            <Image
                className={styles.background}
                width={frameWidth}
                height={frameHeight}
                url={src}
                isBackground
            />
        </div>
    );
};

DragCard.propTypes = propTypes;
DragCard.defaultProps = defaultProps;

export default React.memo(DragCard);
