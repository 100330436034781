import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import useObserver from '../hooks/useObserver';
import { buildThresholdArray } from '../../lib/utils';

import { setMenuIndex as setMenuIndexAction } from '../../actions/LayoutActions';

import styles from '../../styles/partials/observer.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    timeRef: PropTypes.object.isRequired, //eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const CardObserver = ({
    children, active, index, timeRef, className,
}) => {
    const dispatch = useDispatch();
    const { cardIndex } = useSelector(state => state.layout);
    const setMenuIndex = useCallback(idx => dispatch(setMenuIndexAction(idx)), [dispatch]);
    const [ref, entry] = useObserver({
        threshold: buildThresholdArray(),
    });
    const { isIntersecting, intersectionRatio, time } = entry;

    const throttled = useCallback(
        debounce(
            (idx) => {
                setMenuIndex(idx);
            },
            400,
            { leading: false, trailing: true },
        ),
        [setMenuIndex],
    );

    useEffect(() => {
        if (
            active === true
            && index !== cardIndex
            && isIntersecting === true
            && intersectionRatio > 0.7
            && intersectionRatio < 0.95
            && time > timeRef.current
        ) {
            timeRef.current = time; // eslint-disable-line
            throttled(index);
        }
    }, [active, index, isIntersecting, intersectionRatio, time, timeRef, cardIndex, throttled]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={ref}
        >
            {children}
        </div>
    );
};

CardObserver.propTypes = propTypes;
CardObserver.defaultProps = defaultProps;

const WithIntl = CardObserver;

export default WithIntl;
