/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useVideo } from 'react-use';
import { useSelector } from 'react-redux';

import PlayButton from '../buttons/PlayButton';
import SoundButton from '../buttons/SoundButton';
import TouchZone from '../partials/TouchZone';
import Progress from '../partials/Progress';

import styles from '../../styles/cards/video.scss';

const propTypes = {
    index: PropTypes.number.isRequired,
    src: PropTypes.string,
    videoSrc: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    src: null,
    videoSrc: null,
    className: null,
};

const VideoCard = ({
    index, src, videoSrc, className,
}) => {
    const [firstPlayed, setFirstPlayed] = useState(false);
    const [controlsVisible, setControlsVisible] = useState(false);
    const { cardIndex } = useSelector(state => state.layout);
    const [video, state, controls] = useVideo(
        <video className={styles.video} poster={src} playsInline>
            <source src={videoSrc} />
            <track default kind="captions" srcLang="fr" src={null} />
        </video>,
    );

    const {
        time, duration, isPlaying, muted,
    } = state;

    const onSeek = useCallback(
        (t) => {
            controls.seek(t);
        },
        [controls],
    );

    const onPlay = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isPlaying) {
                controls.pause();
            } else {
                controls.play();
            }
            setFirstPlayed(true);
            setControlsVisible(true);
        },
        [controls, isPlaying, setFirstPlayed],
    );

    const onMute = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (muted) {
                controls.unmute();
            } else {
                controls.mute();
            }
        },
        [controls, muted],
    );

    useEffect(() => {
        if (firstPlayed) {
            if (cardIndex === index) {
                controls.play();
            } else {
                controls.pause();
            }
        }
    }, [cardIndex, firstPlayed]);

    useEffect(() => {
        if (time && duration && time === duration) {
            setFirstPlayed(false);
        }
    }, [time, duration, setFirstPlayed]);

    useEffect(() => {
        if (cardIndex !== index) {
            setFirstPlayed(false);
        }
    }, [cardIndex]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <link rel="preload" href={videoSrc} as="video" />
            {video}
            {!firstPlayed ? (
                <PlayButton
                    className={styles.firstPlay}
                    isPlaying={state.isPlaying}
                    onClick={onPlay}
                />
            ) : null}
            {controlsVisible ? (
                <>
                    <PlayButton
                        className={styles.play}
                        isPlaying={state.isPlaying}
                        onClick={onPlay}
                    />

                    <SoundButton className={styles.sound} muted={state.muted} onClick={onMute} />
                    <Progress
                        className={styles.progress}
                        current={time}
                        duration={duration || 0}
                        onClick={onSeek}
                    />
                </>
            ) : null}
            <TouchZone index={index} />
        </div>
    );
};

VideoCard.propTypes = propTypes;
VideoCard.defaultProps = defaultProps;

export default React.memo(VideoCard);
