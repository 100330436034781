import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Play = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="16px"
        height="20px"
        viewBox="0 0 16 20"
        className={className}
        xmlSpace="preserve"
    >
        <polygon fill={color} points="0,20 0,0 16,10 " />
    </svg>
);

Play.propTypes = propTypes;
Play.defaultProps = defaultProps;

export default Play;
