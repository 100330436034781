import Img1 from '../img/vhs/1.jpg';
import Img2 from '../img/vhs/2.jpg';
import Img3 from '../img/vhs/3.jpg';
import Img4 from '../img/vhs/4.jpg';
// import Img5 from '../img/vhs/5.jpg';
import Img6 from '../img/vhs/6.jpg';
import Img7 from '../img/vhs/7.jpg';
import Img8 from '../img/vhs/8.jpg';
import Img9 from '../img/vhs/9.jpg';
import Img10 from '../img/vhs/10.jpg';
import Img11 from '../img/vhs/11.jpg';
import Img12 from '../img/vhs/12.jpg';
import Img13 from '../img/vhs/13.jpg';
import Img14 from '../img/vhs/14.jpg';
import Img15 from '../img/vhs/15.jpg';
import Img16 from '../img/vhs/16.jpg';
import Img17 from '../img/vhs/17.jpg';
import Img18 from '../img/vhs/18.jpg';
import Img19 from '../img/vhs/19.jpg';
import Img20 from '../img/vhs/20.jpg';
import Img21 from '../img/vhs/21.jpg';
import Img22 from '../img/vhs/22.jpg';
import Img23 from '../img/vhs/23.jpg';
import Img24 from '../img/vhs/24.jpg';
import Img25 from '../img/vhs/25.jpg';
import Img26 from '../img/vhs/26.jpg';
import Img27 from '../img/vhs/27.jpg';
import Img28 from '../img/vhs/28.jpg';
import Img29 from '../img/vhs/29.jpg';
import Img30 from '../img/vhs/30.jpg';
import Img31 from '../img/vhs/31.jpg';
import Img32 from '../img/vhs/32.jpg';
import Img33 from '../img/vhs/33.jpg';
import Img34 from '../img/vhs/34.jpg';
import Img35 from '../img/vhs/35.jpg';
import Img36 from '../img/vhs/36.jpg';
import Img37 from '../img/vhs/37.jpg';
import Img38 from '../img/vhs/38.jpg';

import Video7 from '../img/vhs/7.mp4';
import Video21 from '../img/vhs/21.mp4';
import Video24 from '../img/vhs/24.mp4';
import Video29 from '../img/vhs/29.mp4';
import Video30 from '../img/vhs/30.mp4';

// import Img22Large from '../img/vhs/22-large.jpg';
import Img26Drag from '../img/vhs/26-drag.jpg';

import Img220i from '../img/vhs/22-0.jpg';
import Img221i from '../img/vhs/22-1.jpg';
import Img222i from '../img/vhs/22-2.jpg';
import Img223i from '../img/vhs/22-3.jpg';
import Img224i from '../img/vhs/22-4.jpg';
import Img225i from '../img/vhs/22-5.jpg';
import Img226i from '../img/vhs/22-6.jpg';
import Img227i from '../img/vhs/22-7.jpg';
import Img228i from '../img/vhs/22-8.jpg';
import Img229i from '../img/vhs/22-9.jpg';

export default [
    {
        type: 'image',
        src: Img1,
    },
    {
        type: 'image',
        src: Img2,
    },
    {
        type: 'image',
        src: Img3,
    },
    {
        type: 'survey',
        src: Img4,
        choices: [
            {
                name: 'Oui',
                amt: 3,
            },
            {
                name: 'Non',
                amt: 5,
            },
        ],
    },
    // {
    //     type: 'image',
    //     src: Img5,
    // },
    {
        type: 'image',
        src: Img6,
    },
    {
        type: 'video',
        src: Img7,
        videoSrc: Video7,
    },
    {
        type: 'image',
        src: Img8,
    },
    {
        type: 'image',
        src: Img9,
    },
    {
        type: 'image',
        src: Img10,
    },
    {
        type: 'image',
        src: Img11,
    },
    {
        type: 'image',
        src: Img12,
    },
    {
        type: 'image',
        src: Img13,
    },
    {
        type: 'image',
        src: Img14,
    },
    {
        type: 'image',
        src: Img15,
    },
    {
        type: 'image',
        src: Img16,
    },
    {
        type: 'image',
        src: Img17,
    },
    {
        type: 'image',
        src: Img18,
    },
    {
        type: 'image',
        src: Img19,
    },
    {
        type: 'image',
        src: Img20,
    },
    {
        type: 'video',
        src: Img21,
        videoSrc: Video21,
    },
    {
        type: 'swipe',
        src: Img22,
        items: [
            { type: 'image', src: Img220i },
            { type: 'image', src: Img221i },
            { type: 'image', src: Img222i },
            { type: 'image', src: Img223i },
            { type: 'image', src: Img224i },
            { type: 'image', src: Img225i },
            { type: 'image', src: Img226i },
            { type: 'image', src: Img227i },
            { type: 'image', src: Img228i },
            { type: 'image', src: Img229i },
        ],
    },
    {
        type: 'image',
        src: Img23,
    },
    {
        type: 'video',
        src: Img24,
        videoSrc: Video24,
    },
    {
        type: 'image',
        src: Img25,
    },
    {
        type: 'drag',
        src: Img26,
        srcDrag: Img26Drag,
        width: 320,
        height: 320,
    },
    {
        type: 'image',
        src: Img27,
    },
    {
        type: 'image',
        src: Img28,
    },
    {
        type: 'video',
        src: Img29,
        videoSrc: Video29,
    },
    {
        type: 'image',
        src: Img31,
    },
    {
        type: 'video',
        src: Img30,
        videoSrc: Video30,
    },
    {
        type: 'image',
        src: Img32,
    },
    {
        type: 'map',
        src: Img33,
        items: [
            {
                src: Img33,
                lat: 45.52,
                lng: -73.7,
                text: 'Vue du balcon de Marcel',
            },
            {
                src: Img34,
                lat: 45.48,
                lng: -73.66,
                text: 'Nicolas, petit-fils de Marcel',
            },
            {
                src: Img35,
                lat: 45.46,
                lng: -73.62,
                text: 'Homme inconnu',
            },
            {
                src: Img36,
                lat: 45.51,
                lng: -73.48,
                text: 'Femme inconnue',
            },
            {
                src: Img37,
                lat: 45.54,
                lng: -73.515,
                text: 'Femme de marcel',
            },
        ],
    },
    {
        type: 'image',
        src: Img38,
    },
];
