import React from 'react';
import { defineMessages } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';
import Header from '../menus/Header';
import Cards from '../cards/Cards';

import styles from '../../styles/pages/cards.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'VHS',
    },
});

const propTypes = {
    page: AppPropTypes.page.isRequired,
};

const CardsPage = ({ page }) => (
    <div className={styles.container}>
        <PageMeta title={messages.metaTitle} />
        <Header />
        <Cards items={page.cards} />
    </div>
);
CardsPage.propTypes = propTypes;

export default CardsPage;
