import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import Muted from '../icons/Muted';
import Unmuted from '../icons/Unmuted';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/buttons/sound-button.scss';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    muted: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    muted: false,
    onClick: null,
    className: null,
};

const SoundButton = ({
    width, height, muted, onClick, className,
}) => (
    <Button
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        onClick={onClick}
        style={{ width, height, borderRadius: width !== null ? width / 2 : null }}
    >
        {!muted ? <Unmuted /> : <Muted />}
    </Button>
);

SoundButton.propTypes = propTypes;
SoundButton.defaultProps = defaultProps;

export default SoundButton;
