import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withUrlGenerator } from '@folklore/react-container';

// import * as AppPropTypes from '../../lib/PropTypes';

import usePage from '../hooks/usePage';
import useScrollTop from '../hooks/useScrollTop';

import {
    setCardIndex as setCardIndexAction,
    setScrollIndex as setScrollIndexAction,
    setMenuOpen as setMenuOpenAction,
} from '../../actions/LayoutActions';

import ErrorPage from './Error';

import {
    Home, Cards, Group1, Group2, Test,
} from './index';

const propTypes = {
    // urlGenerator: AppPropTypes.urlGenerator.isRequired,
    params: PropTypes.object, // eslint-disable-line
    match: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
    params: {},
    match: {},
};

const PageLoader = (props) => {
    const {
        match: {
            url = null,
            params: { slug = 'tap', index = 0 },
        },
    } = props;

    const dispatch = useDispatch();
    const setCardIndex = useCallback(idx => dispatch(setCardIndexAction(idx)), [dispatch]);
    const setScrollIndex = useCallback(open => dispatch(setScrollIndexAction(open)), [dispatch]);
    const setMenuOpen = useCallback(open => dispatch(setMenuOpenAction(open)), [dispatch]);

    // Initial setup
    useEffect(() => {
        setCardIndex(index);
        setScrollIndex(index);
        setMenuOpen(false);
    }, [url, index]);

    usePage({ name: slug });
    useScrollTop({ url });

    const page = useSelector(state => state.site.page);
    const statusCode = useSelector(state => state.site.statusCode);

    if (statusCode !== null) {
        return <ErrorPage />;
    }

    if (!page) {
        return null;
    }

    switch (url) {
    case '/':
        return <Home page={page} />;
    case '/1':
        return <Group1 page={page} />;
    case '/2':
        return <Group2 page={page} />;
    case '/test':
        return <Test page={page} />;
    default:
        return <Cards page={page} />;
    }
};

PageLoader.propTypes = propTypes;
PageLoader.defaultProps = defaultProps;

const WithUrlGenerator = withUrlGenerator()(PageLoader);

export default React.memo(WithUrlGenerator);
