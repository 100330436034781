import React from 'react';
import { Route, Switch } from 'react-router';
import { withUrlGenerator } from '@folklore/react-container';

import * as AppPropTypes from '../lib/PropTypes';
import MainLayout from './layouts/Main';
import PageLoader from './pages/PageLoader';
import ErrorPage from './pages/Error';

import '../styles/main.global.scss';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {};

const App = ({ urlGenerator }) => (
    <MainLayout>
        <Switch>
            <Route exact path={urlGenerator.route('home')} component={PageLoader} />
            <Route exact path={urlGenerator.route('test')} component={PageLoader} />
            <Route exact path={urlGenerator.route('group1')} component={PageLoader} />
            <Route exact path={urlGenerator.route('group2')} component={PageLoader} />
            <Route exact path={urlGenerator.route('cards')} component={PageLoader} />
            <Route path="*" component={ErrorPage} />
        </Switch>
    </MainLayout>
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default withUrlGenerator()(App);
