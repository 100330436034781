import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/partials/image.scss';

const propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    isBackground: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    width: null,
    height: null,
    isBackground: false,
    className: null,
};

const Image = ({
    width, height, url, isBackground, className,
}) => {
    let style = {};
    if (width || height) {
        style = { ...style, width: width || null, height: height || null };
    }
    return isBackground ? (
        <div
            className={classNames([
                styles.container,
                styles.isBackground,
                {
                    [className]: className !== null,
                },
            ])}
            style={{ ...style, backgroundImage: `url(${url})` }}
        />
    ) : (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={style}
        >
            <img src={url} alt="test" />
        </div>
    );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default React.memo(Image);
