import React from 'react';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { withUrlGenerator } from '@folklore/react-container';

import PageMeta from '../partials/PageMeta';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/home.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'VHS',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const HomePage = ({ urlGenerator }) => (
    <div className={styles.container}>
        <PageMeta title={messages.metaTitle} />
        <div className={styles.inner}>
            <Link to={urlGenerator.route('cards', { slug: 'both', index: 0 })}>
                Navigation double
            </Link>
            <Link to={urlGenerator.route('cards', { slug: 'tap', index: 0 })}>Navigation tap</Link>
            <Link to={urlGenerator.route('cards', { slug: 'swipe', index: 0 })}>
                Navigation swipe
            </Link>
            <Link to={urlGenerator.route('cards', { slug: 'menu', index: 0 })}>
                Menu avec scroll
            </Link>
            <Link to={urlGenerator.route('cards', { slug: 'close', index: 0 })}>
                Menu avec scroll et X
            </Link>
            <Link to={urlGenerator.route('cards', { slug: 'large', index: 0 })}>
                Menu plein écran
            </Link>
        </div>
    </div>
);
HomePage.propTypes = propTypes;

export default withUrlGenerator()(HomePage);
